import React, { Component } from "react";
import { Table, Button, Icon, Header, Message, Segment } from "semantic-ui-react";
import Moment from "react-moment";
import moment from "moment";
import { Authentication } from "../../../../lib/sso";

export default class ReferralList extends Component {
  renderList() {
    if (this.props.data && this.props.data.length) {
      const list = this.props.data.map((data) => {
        return (
          <Table.Row
            key={data._id}
            onClick={() => {
              this.props.action(data._id);
            }}
            className={
              moment.utc().diff(moment.utc(data.createdAt), "days") > 7 && data.status === "New" ? "redItem" : ""
            }
          >
            <Table.Cell>
              <Header>R{data.refNum}</Header>
            </Table.Cell>
            <Table.Cell>
              {data.employee &&
                data.employee.firstName +
                  " " +
                  data.employee.lastName +
                  " (#" +
                  (data.employee.ref ? data.employee.ref : "") +
                  ")"}
            </Table.Cell>
            <Table.Cell>
              {data.cm && data.cm.firstName ? data.cm.firstName + " " + data.cm.lastName : "Not Assigned"}
            </Table.Cell>
            {this.props.showCM && (
              <Table.Cell>
                {data.case && data.case.cm ? data.case.cm.firstName + " " + data.case.cm.lastName : "Not Assigned"}
              </Table.Cell>
            )}
            {this.props.showCompany && <Table.Cell>{data.company ? data.company.name : "Undefined"}</Table.Cell>}
            <Table.Cell>
              <Moment format={"DD/MM/YYYY"}>{data.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell>{data.type}</Table.Cell>
            <Table.Cell>{data.reason}</Table.Cell>
            <Table.Cell>{data.condition}</Table.Cell>
            <Table.Cell>{data.status}</Table.Cell>
            {/* <Table.Cell></Table.Cell> */}
            <Table.Cell>
              <Header>{data.case && data.case.refNum !== undefined ? "C" + data.case.refNum : "--"}</Header>
            </Table.Cell>
            {this.props.allowAdd && <Table.Cell />}
            {/* Only show if admin */}
            {Authentication.can("navigator_admin.permission") && (
              <Table.Cell>
                <Button
                  icon
                  color={data.archived ? "positive" : "negative"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    {
                      console.log(data);
                    }
                    this.props.onToggleArchive(data._id, !!data.archived);
                  }}
                >
                  {data.archived ? <Icon name="folder open" /> : <Icon name="archive" />}
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        );
      });
      return list;
    }
  }

  render() {
    if (this.props.data && this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button icon positive size="tiny" onClick={() => this.props.add()}>
                <Icon name="plus" /> Add New Referral
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ref</Table.HeaderCell>
            <Table.HeaderCell>Patient</Table.HeaderCell>
            <Table.HeaderCell>Assigned To Company Referral Manager</Table.HeaderCell>
            {this.props.showCM && <Table.HeaderCell>Assigned To Nurse Case Manager</Table.HeaderCell>}
            {this.props.showCompany && <Table.HeaderCell>Company</Table.HeaderCell>}
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell>Condition</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            {/* <Table.HeaderCell>Last Activity</Table.HeaderCell> */}
            <Table.HeaderCell>Case #</Table.HeaderCell>
            {this.props.allowAdd && (
              <Table.HeaderCell textAlign="center" width={1}>
                <Button icon positive size="tiny" onClick={() => this.props.add()}>
                  <Icon name="plus" />
                </Button>
              </Table.HeaderCell>
            )}
            {Authentication.can("navigator_admin.permission") && <Table.HeaderCell>[ADMIN] Archive</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
