import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Table,
  Button,
  Icon,
  Menu,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../../actions/api";
import "react-phone-input-2/lib/semantic-ui.css";
import { toast } from "react-semantic-toasts";
import { Link } from "react-router-dom";

import { EditorState, Modifier } from "draft-js";
import PropTypes from "prop-types";
import _appConfig from "../../../_appConfig";
import { FaBookOpen, FaCalendar, FaCheckSquare, FaMoneyBill, FaVideo } from "react-icons/fa";
class ShortcodeDropdown extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  state = {
    open: false,
  };

  openPlaceholderDropdown = () => this.setState({ open: !this.state.open });

  addPlaceholder = (placeholder) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  placeholderOptions = [
    { key: "to", value: "{TO}", text: "To Email" },
    {
      key: "employee_firstname",
      value: "{EMP_FIRSTNAME}",
      text: "Employee FirstName",
    },
    {
      key: "employee_lastname",
      value: "{EMP_LASTNAME}",
      text: "Employee LastName",
    },
    { key: "employee_ID", value: "{EMP_ID}", text: "Employee ID" },
    { key: "url_portal", value: "{PORTAL_LINK}", text: "URL Portal" },
    { key: "url_app", value: "{APP_LINK}", text: "URL App" },
    { key: "username", value: "{USERNAME}", text: "Username" },
    { key: "password", value: "{PASSWORD}", text: "Password" },
    {
      key: "password_reset_link",
      value: "{PASSWORD_RESET_LINK}",
      text: "Password Reset Link",
    },
    { key: "2FA", value: "{2FA}", text: "2FA" },
    {
      key: "ref_name",
      value: "{REFERRAL_MAN_NAME}",
      text: "Referral Manager Name",
    },
    {
      key: "ref_email",
      value: "{REFERRAL_MAN_EMAIL}",
      text: "Referral Manager Email",
    },
    {
      key: "ca_email",
      value: "{COMPANY_ADMINS}",
      text: "Company Admins Name & Email",
    },
    // {key: "ca_name", value: "{COMPANY_ADMIN_NAME}", text: "Company Admin Name"},
    // {key: "ca_email", value: "{COMPANY_ADMIN_EMAIL}", text: "Company Admin Email"},
    {
      key: "ncm_name",
      value: "{NURSE_CASEMAN_NAME}",
      text: "Nurse Case Manager Name",
    },
    {
      key: "ncm_email",
      value: "{NURSE_CASEMAN_EMAIL}",
      text: "Nurse Case Manager Email",
    },
    { key: "company_name", value: "{COMPANY_NAME}", text: "Company Name" },
    { key: "c_number", value: "{CASE_NUMBER}", text: "Case Number" },
    { key: "r_number", value: "{REFERRAL_NUMBER}", text: "Referral Number" },
    {
      key: "case_note_type",
      value: "{CASE_NOTE_TYPE}",
      text: "Case Note Type",
    },
    // {key: "case_note_subject", value: "{CASE_NOTE_SUBJECT}", text: "Case Note Subject"},
    {
      key: "case_note_message",
      value: "{CASE_NOTE_MESSAGE}",
      text: "Case Note Message",
    },
    {
      key: "case_note_date",
      value: "{CASE_NOTE_DATE}",
      text: "Case Note Due Date",
    },
    {
      key: "case_note_files",
      value: "{CASE_NOTE_FILES}",
      text: "Num. of Case Note Files",
    },
    //ASSESSMENT
    {
      key: "clinician_decision",
      value: "{CLINICIAN_DECISION}",
      text: "Clinician Decision Selected",
    },
    {
      key: "clinician_decision_date",
      value: "{CLINICIAN_DECISION_DATE}",
      text: "Date of Clinician Decision ",
    },
    {
      key: "health_assessment_date",
      value: "{HEALTH_ASSESSMENT_DATE}",
      text: "Health Assessment Booking Date",
    },
    {
      key: "followup_consultation_date",
      value: "{FOLLOWUP_CONSULTATION_DATE}",
      text: "Follow Up Consultation Date",
    },
    {
      key: "assessment_test_date",
      value: "{ASSESSMENT_TEST_DATE}",
      text: "Date Test Result Returned",
    },
    {
      key: "clinician_decision_notes",
      value: "{CLINICIAN_DECISION_NOTES}",
      text: "Clinician Decision Notes",
    },
    {
      key: "clinician_name",
      value: "{CLINICIAN_NAME}",
      text: "Clinician Name",
    },
    {
      key: "health_assessment_clinician_name",
      value: "{HEALTH_ASSESSMENT_CLINICIAN_NAME}",
      text: "Health Assessment Clinician Name ",
    },
  ];

  listItem = this.placeholderOptions.map((item) => (
    <li
      onClick={this.addPlaceholder.bind(this, item.value)}
      key={item.key}
      className="rdw-dropdownoption-default placeholder-li"
    >
      {item.text}
    </li>
  ));

  render() {
    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper"
        aria-label="rdw-block-control"
      >
        <div
          className="rdw-dropdown-wrapper rdw-block-dropdown"
          aria-label="rdw-dropdown"
          style={{ minWidth: "250px" }}
        >
          <div className="rdw-dropdown-selectedtext" title="Shortcodes">
            <span>Shortcodes </span>
            <div
              className={`rdw-dropdown-caretto${
                this.state.open ? "close" : "open"
              }`}
            ></div>
          </div>
          <ul
            className={`rdw-dropdown-optionwrapper ${
              this.state.open ? "" : "placeholder-ul"
            }`}
          >
            {this.listItem}
          </ul>
        </div>
      </div>
    );
  }
}

export default class SembleSettings extends Component {
  state = {
    company: {},
    loading: true,
  };

  getData() {
    this.setState({ loadingRecent: true });
    api.company.getCompany(this.props.match.params.id).then((data) => {
      this.setState({
        //semble arrays
        company: {
          ...this.state.company,
          ...data,
        },
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.getData();
  }

  handleSubmit = (data) => {
    return api.company
      .editCompany(data, this.props.match.params.id)
      .then(() => {
        this.getData();
        toast({
          type: "success",
          icon: "pencil",
          title: <h3>Company Updated</h3>,
          description: (
            <span>
              <p>Successfully updated Company</p>
            </span>
          ),
          time: 4000,
        });
      });
  };

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  handleStyleMenuClick = (e, { name }) => this.setState({ style_menu: name });

  renderMenu() {
    let items = [
      {
        to: `/company/${this.props.match.params.id}`,
        content: <><Icon name="backward" /> Back to Company</>
      },
    ];
    return (
      <Menu className="subMenu">

        <Menu.Item
          onClick={this.handleMenuClick}
          as={Link}
          to={`/company/${this.props.match.params.id}`}
        >
          <Icon name="backward" />
          Back to Company
        </Menu.Item>
      </Menu>
    );
  }
  render() {
    
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle ">
          {this.state.company.name} - Semble Settings
        </Header>
        <Segment basic className="no-hpad">
          <Segment basic className="no-pad no-marg ">
            {this.renderMenu()}
          </Segment>

          <Button 
            style={{marginTop: '5px'}}
            primary
            onClick={() => {
              api.company.syncSemble(this.props.match.params.id).then(res => {
                console.log("🚀 ~ SembleSettings ~ api.company.syncSemble ~ res:", res)
                if(res.success){
                  //toast success
                  this.getData();
                }
              })
            }}
          >
            Sync with Semble
          </Button>
          <p>Last Semble Sync: {this.state.company.lastSemble}</p>

          <Grid columns={"1"}>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Header as={"h4"}>
                    Locations
                  </Header>
                  <Table>
                    <Table.Header>
                      <Table.HeaderCell>Location (GroupName)</Table.HeaderCell>
                      <Table.HeaderCell>Room</Table.HeaderCell>
                      <Table.HeaderCell>Active Services Provided</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {this.state.company.semble_locations && this.state.company.semble_locations.length > 0 ? this.state.company.semble_locations.sort((a, b) => {
                            if (a.groupName < b.groupName) return -1;
                            if (a.groupName > b.groupName) return 1;
                            return 0;
                        }).map((l) => {
                        // console.log(l);
                        return <Table.Row>
                          <Table.Cell>{l.groupName}</Table.Cell>
                          <Table.Cell>{l.name}</Table.Cell>
                          <Table.Cell><ul>
                            {l.servicesProvided.filter(a => a.status == "active").map((service) => {
                              return <li>{service.name} - {service.productType} @ £{service.price} for {service.duration} mins</li>
                            })}
                            </ul></Table.Cell>
                        </Table.Row>
                      }) : <Table.Row>
                        <Table.Cell colSpan={3} style={{textAlign: "center"}}>No Data</Table.Cell></Table.Row>}
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>

              </Grid.Row>
              <Grid.Row>

              <Grid.Column>
                <Segment>
                  <Header as={"h4"}>
                    Products
                  </Header>
                  <Table>
                    <Table.Header>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Price</Table.HeaderCell>
                      <Table.HeaderCell>Duration</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {this.state.company.semble_services && this.state.company.semble_services.length > 0 ? this.state.company.semble_services.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        }).map((p) => {
                        return <Table.Row>
                          <Table.Cell>{p.name} {" "}
                            {p.isBookable && <FaCalendar />} 
                            {p.isVideoConsultation && <FaVideo />} 
                            {p.requiresConfirmation && <FaCheckSquare />}
                            {p.requiresPayment && <FaMoneyBill />}
                          </Table.Cell>
                          <Table.Cell>£{p.price}</Table.Cell>
                          <Table.Cell>{p.duration}</Table.Cell>
                          <Table.Cell>{p.productType}</Table.Cell>
                        </Table.Row>
                      }) : <Table.Row>
                        <Table.Cell colSpan={3} style={{textAlign: "center"}}>No Data</Table.Cell></Table.Row>}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Cell colSpan={4}>
                        <FaCalendar /> Bookable Service{" "} 
                        <FaVideo /> Video Consultation{" "}
                        <FaCheckSquare /> Requires Confirmation{" "}
                        <FaMoneyBill /> Requires Payment{" "}
                      </Table.Cell>
                    </Table.Footer>
                  </Table>
                </Segment>
              </Grid.Column>

              </Grid.Row>
              <Grid.Row>

              <Grid.Column>
                <Segment>
                  <Header as={"h4"}>
                    Clinicians
                  </Header>
                  <Table>
                    <Table.Header>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Qualifications</Table.HeaderCell>
                      <Table.HeaderCell>Registration</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {this.state.company.semble_clinicians && this.state.company.semble_clinicians.length > 0 ? this.state.company.semble_clinicians.sort((a, b) => {
                            if (a.lastName < b.lastName) return -1;
                            if (a.lastName > b.lastName) return 1;
                            return 0;
                        }).map((u) => {
                        console.log(u)
                        return <Table.Row>
                          <Table.Cell>{u.fullName}</Table.Cell>
                          <Table.Cell>{u.email}</Table.Cell>
                          <Table.Cell>{u.qualifications}</Table.Cell>
                          <Table.Cell>{u.registration}</Table.Cell>
                        </Table.Row>
                      }) : <Table.Row>
                        <Table.Cell colSpan={3} style={{textAlign: "center"}}>No Data</Table.Cell></Table.Row>}
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    );
  }
}
