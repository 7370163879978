import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Grid } from 'semantic-ui-react';

class PaginationMenu extends React.Component {
    handlePageChange = (e, { activePage }) => {
        this.props.setPageNum(activePage);
        if (this.props.preserveOptions) {
            sessionStorage.setItem(`${this.props.preserveOptions}_pageNum`, activePage);
        }
        this.props.queryData();
    };

    render() {
        const { pageNum, totalPages } = this.props;
        return (
            <Grid.Row columns={1}>
                <Grid.Column textAlign="right">
                    <Pagination
                        style={{ flexWrap: "wrap" }}
                        defaultActivePage={pageNum}
                        totalPages={totalPages}
                        onPageChange={this.handlePageChange}
                    />
                </Grid.Column>
            </Grid.Row>
        );
    }
}

PaginationMenu.propTypes = {
    pageNum: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    setPageNum: PropTypes.func.isRequired,
    queryData: PropTypes.func.isRequired,
    preserveOptions: PropTypes.string,
};

export default PaginationMenu;