import React from "react";
import { Pagination, Segment, Select, Message, Input, Button } from "semantic-ui-react";

const pageSizes = [10, 25, 50, 100];

export default class PaginateWrapper extends React.Component {
  state = {
    pageSize: pageSizes[3],
    pageNum: 1,
    totalPages: 0,
    totalItems: 0,
    search: "",
    filters: {},
    items: [],
    loading: true,
    globalError: null,
  };

  componentDidMount() {
    if (this.props.active !== undefined) {
      this.setState({ filters: { show_archived: false } }, () => this.queryData());
    } else {
      this.queryData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataQuery !== this.props.dataQuery) {
      this.queryData();
    }
  }

  handleSearch = (e, { name, value }) =>
    this.setState({ [name]: value }, () => {
      this.queryData();
    });

  queryData() {
    this.setState(
      {
        loading: true,
        globalError: null,
      },
      () => {
        this.props
          .dataQuery(this.state.pageNum, this.state.pageSize, this.state.search, this.state.filters)
          .then((data) => {
            this.setState({
              pageNum: data.page,
              totalPages: data.pages,
              totalItems: data.total,
              items: data.docs,
            });
          })
          .catch((err) => {
            let globalError = "There was an unexpected error while retrieving data from the server";

            if (err.response !== undefined) {
              globalError = err.response.data.message;
            }

            this.setState({
              globalError,
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      },
    );
  }

  resetQuery() {
    this.setState(
      {
        pageNum: 1,
        search: "",
        filters: {},
      },
      () => {
        this.queryData();
      },
    );
  }

  render() {
    return (
      <Segment basic loading={this.state.loading}>
        {this.state.globalError && (
          <Message negative>
            <Message.Header>ERROR</Message.Header>
            {this.state.globalError}
          </Message>
        )}
        <div>
          {this.props.search !== undefined ? (
            <Input
              style={{ width: "20%" }}
              name="search"
              value={this.state.search}
              onChange={this.handleSearch}
              maxResults={0}
              showNoResults={false}
              placeholder="Search..."
            />
          ) : (
            <React.Fragment />
          )}
          {this.props.active !== undefined ? (
            <Button.Group style={this.props.search !== undefined ? { paddingLeft: "15%" } : {}}>
              <Button
                content={`Show ${this.props.active}`}
                positive={this.state.filters.show_archived === true}
                onClick={() =>
                  this.setState(
                    {
                      filters: { ...this.state.filters, show_archived: true },
                    },
                    () => {
                      this.queryData();
                    },
                  )
                }
              />
              <Button
                content={`Hide ${this.props.active}`}
                negative={this.state.filters.show_archived === false}
                onClick={() =>
                  this.setState(
                    {
                      filters: { ...this.state.filters, show_archived: false },
                    },
                    () => {
                      this.queryData();
                    },
                  )
                }
              />
            </Button.Group>
          ) : (
            <React.Fragment />
          )}
          <div style={{ float: "right" }}>
            <label>Page Size: </label>
            <Select
              options={pageSizes.map((i) => {
                return {
                  key: i,
                  value: i,
                  text: i,
                };
              })}
              value={this.state.pageSize}
              onChange={(e, data) => {
                this.setState(
                  {
                    pageSize: data.value,
                    pageNum: 1,
                  },
                  () => {
                    this.queryData();
                  },
                );
              }}
            />
          </div>
        </div>
        <div
          style={
            this.props.active === undefined && this.props.search === undefined
              ? { paddingTop: "" }
              : { paddingTop: "1rem " }
          }
        >
          {this.props.render(this.state.items)}
        </div>
        {this.state.items.length >= 1 && (
          <div style={{ textAlign: "right", paddingTop: "1rem" }}>
            <Pagination
              defaultActivePage={this.state.pageNum}
              totalPages={this.state.totalPages}
              onPageChange={(e, { activePage }) => {
                this.setState({ pageNum: activePage }, () => this.queryData());
              }}
            />
          </div>
        )}
      </Segment>
    );
  }
}
