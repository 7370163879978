import React, { Component } from "react";
import { Form, Segment, Icon, Header, Divider, Label } from "semantic-ui-react";
import api from "../../../../actions/api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";
import PostCoder from "../../common/postCoder";
import AppConfig from "../../../../_appConfig";
import country_list from "../../../common/CountryList.js";

export default class EditCompany extends Component {
  state = {
    data: {
      sendEmailEmp: true,
      name: "",
      website: "",
      email: "",
      telephone_number: "",
      company_info: "",
      country: "United Kingdom",
      company_address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      contact_types: {
        phone: false,
        email: true,
        post: false,
      },
      pirkx: {
        connectionId: "",
      },
      archived: false,
    },
    companies_list: [],
    errors: {},
    loading: false,
  };

  componentDidMount() {
    api.company.getCompany(this.props.match.params.id).then((data) => {
      this.setState({
        data: {
          ...this.state.data,
          ...data,
        },
      });
    });

    //api.company.getAllDropdown(),
    //companies_list: res[3],

    api.company.getAllDropdown().then((res) => {
      this.setState({
        companies_list: res.filter(
          (r) => r.value !== this.props.match.params.id
        ),
      });
    });
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleAddressChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        company_address: {
          ...this.state.data.company_address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't Be Empty.";
    if (!data.website) errors.website = "Can't Be Empty.";
    if (!data.email) errors.email = "Can't Be Empty.";
    if (
      data.email &&
        /^[\w\.\+\-]+@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(data.email) === false
    )
      errors.email = "Invalid Format.";
    if (!data.telephone_number) errors.telephone_number = "Can't Be Empty.";
    if (data.telephone_number && data.telephone_number.toString().length < 4)
      errors.telephone_number = "Invalid Number.";
    if (!data.country) errors.country = "Can't Be Empty.";
    if (!data.company_address.addressline1)
      errors.addressline1 = "Can't Be Empty.";
    if (!data.company_address.street) errors.street = "Can't Be Empty.";
    if (!data.company_address.posttown) errors.posttown = "Can't Be Empty.";
    if (!data.company_address.county) errors.county = "Can't Be Empty.";
    if (!data.company_address.postcode) errors.postcode = "Can't Be Empty.";
    if (data.sendSemble && !data.sembleAPI) errors.sembleAPI = "Key Required!";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validate(this.state.data);
    let data = this.state.data;
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      api.company
        .editCompany({
          name: data.name,
          email: data.email,
          website: data.website,
          telephone_number: data.telephone_number,
          pirkx: data.pirkx,
          company_info: data.company_info,
          microsoft_bussinessId: data.microsoft_bussinessId,
          company_address: data.company_address,
          sembleAPI: data.sembleAPI,
          sendSemble: data.sendSemble,
          sendEmailEmp: data.sendEmailEmp,
          country: data.country,
          archived: data.archived,
          company_manager: data.company_manager,
          managed_companies: data.managed_companies,
        }, this.props.match.params.id)
        .then(() => {
          this.props.history.push(`/company/${this.props.match.params.id}`);
        })
        .catch((e) => {
          this.setState({ loading: false });
          console.log(e);
        });
    }
  };

  company_address = (addr) => {
    this.setState({
      data: {
        ...this.state.data,
        company_address: {
          address: addr.summaryline,
          addressline1: addr.addressline1,
          addressline2: addr.addressline2,
          street: addr.street,
          posttown: addr.posttown,
          county: addr.county,
          postcode: addr.postcode,
          latitude: addr.latitude,
          longitude: addr.longitude,
          organisation: addr.organisation,
        },
      },
    });
  };
  
  render() {
    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle ">
          Edit Company
        </Header>
        <Segment className="no-marg border">
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                name="name"
                label="Company Name"
                value={this.state.data.name}
                onChange={this.handleChange}
                placeholder="Company Name"
                error={this.state.errors.name}
              />
              <Form.Input
                name="email"
                label="Email"
                value={this.state.data.email}
                onChange={this.handleChange}
                placeholder="Email"
                error={this.state.errors.email}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="website"
                label="Website"
                value={this.state.data.website}
                onChange={this.handleChange}
                placeholder="Website"
                error={this.state.errors.website}
              />
            </Form.Group>
            <Form.Field
              error={
                this.state.errors.telephone_number !== undefined ? true : false
              }
            >
              <div
                style={{
                  paddingBottom: "14px",
                  paddingTop: "5px",
                  fontSize: ".92857143em",
                }}
              >
                <label>
                  <strong>Preferred Contact Number</strong>
                </label>
                <PhoneInput
                  inputStyle={{
                    marginLeft: "30px",
                    maxWidth: "calc(100% - 30px)",
                    fontFamily:
                      "Lato, Helvetica Neue, Arial, Helvetica, sans-serif !important",
                  }}
                  country={"gb"}
                  value={this.state.data.telephone_number}
                  placeholder="Preferred Contact Number"
                  onChange={(value) => {
                    this.setState({
                      data: { ...this.state.data, telephone_number: value },
                    });
                  }}
                />
              </div>
              {this.state.errors.telephone_number && (
                <Label pointing prompt>
                  {this.state.errors.telephone_number}
                </Label>
              )}
            </Form.Field>
            <Form.Group widths="equal">
              <Form.TextArea
                name="company_info"
                label="Company Info"
                value={this.state.data.company_info}
                onChange={this.handleChange}
                placeholder="Additional Company Information"
              />
            </Form.Group>
            {this.state.data.country === "United Kingdom" ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Address Lookup</label>
                  {/* <label>Please Use Demo Postcode: NR14 7PZ</label> */}
                  <PostCoder
                    apiKey={AppConfig.postcoder_api_key}
                    addressSelectedCallback={this.company_address}
                    identifier={AppConfig.postcoder_identifier}
                    country={"UK"}
                    showAddress={false}
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              <React.Fragment />
            )}
            <Form.Group widths="equal">
              <Form.Input
                name="addressline1"
                label="Address Line 1"
                value={
                  this.state.data.company_address &&
                  this.state.data.company_address.addressline1
                }
                onChange={this.handleAddressChange}
                placeholder="Address Line 1"
                error={this.state.errors.addressline1}
              />
              <Form.Input
                name="addressline2"
                label="Address Line 2"
                value={
                  this.state.data.company_address &&
                  this.state.data.company_address.addressline2
                }
                onChange={this.handleAddressChange}
                placeholder="Address Line 2"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="street"
                label="Street"
                value={
                  this.state.data.company_address &&
                  this.state.data.company_address.street
                }
                onChange={this.handleAddressChange}
                placeholder="Street"
                error={this.state.errors.street}
              />
              <Form.Input
                name="posttown"
                label="Town / City"
                value={
                  this.state.data.company_address &&
                  this.state.data.company_address.posttown
                }
                onChange={this.handleAddressChange}
                placeholder="Postal Town / City"
                error={this.state.errors.posttown}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="county"
                label="County"
                value={
                  this.state.data.company_address &&
                  this.state.data.company_address.county
                }
                onChange={this.handleAddressChange}
                placeholder="County"
                error={this.state.errors.county}
              />
              <Form.Input
                name="postcode"
                label="Postcode"
                value={
                  this.state.data.company_address &&
                  this.state.data.company_address.postcode
                }
                onChange={this.handleAddressChange}
                placeholder="Postcode"
                error={this.state.errors.postcode}
              />
              <Form.Dropdown
                name="country"
                label="Country"
                value={this.state.data.country}
                onChange={this.handleDropdownChange}
                placeholder="Please Select A Country"
                options={country_list}
                search
                clearable
                selection
                lazyLoad
                error={this.state.errors.country}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                label="Archived"
                name="archived"
                checked={this.state.data.archived}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      archived: !this.state.data.archived,
                    },
                  });
                }}
              />

              <Form.Checkbox
                label={
                  (this.state.data.sendEmailEmp ? "" : "Do not ") +
                  "Send email notification to Employee on creation"
                }
                name="sendEmailEmp"
                toggle
                checked={this.state.data.sendEmailEmp}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      sendEmailEmp: !this.state.data.sendEmailEmp,
                    },
                  });
                }}
              />
              <Form.Checkbox
                label={
                  (this.state.data.company_manager ? "" : "Not ") +
                  "Manages Companies"
                }
                name="company_manager"
                toggle
                checked={this.state.data.company_manager}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      company_manager: !this.state.data.company_manager,
                    },
                  });
                }}
              />
              <Form.Checkbox
                label={
                  (this.state.data.sendSemble ? "" : "Not ") +
                  "Linked to Semble"
                }
                name="sendSemble"
                toggle
                checked={this.state.data.sendSemble}
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      sendSemble: !this.state.data.sendSemble,
                    },
                  });
                }}
              />
              {this.state.data.sendSemble && <Form.Input 
                label={"Semble API Key"}
                name="sembleAPI"
                value={
                  this.state.data.sembleAPI
                }
                onChange={this.handleChange}
                error={this.state.errors.sembleAPI}
              />}

              {this.state.data.company_manager && (
                <Form.Dropdown
                  name="managed_companies"
                  label="Associated Companies"
                  onChange={(e, data) => {
                    let managed_companies = data.value;

                    this.setState({
                      data: { ...this.state.data, managed_companies },
                    });
                  }}
                  options={this.state.companies_list}
                  value={this.state.data.managed_companies}
                  multiple
                  clearable
                  seletion
                  placeholder="Companies Managed with Company"
                />
              )}

              <Form.Input
                name="pirkx_connectionId"
                label="Prikx: Connection ID"
                value={this.state.data.pirkx.connectionId}
                onChange={(e) =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      pirkx: {
                        connectionId: e.target.value,
                      },
                    },
                  })
                }
                placeholder="Pirkx Connection ID"
                error={this.state.errors.pirkx_connectionId}
              />
              <Form.Input
                name="microsoft_bussinessId"
                label="Microsoft BussinessId"
                value={this.state.data.microsoft_bussinessId}
                onChange={(e) =>
                  this.setState({
                    data: {
                      ...this.state.data,
                      microsoft_bussinessId: e.target.value,
                    },
                  })
                }
                placeholder="Pirkx Connection ID"
                error={this.state.errors.pirkx_connectionId}
              />
            </Form.Group>
            {/* <div
              style={{
                paddingBottom: "14px",
                paddingTop: "5px",
                fontSize: ".92857143em",
              }}
            >
              <label>
                <strong>Preferred Contact Method(s):</strong>
              </label>
            </div>
            <Form.Group inline>
              <Form.Checkbox
                label="Email"
                name="email"
                checked={
                  this.state.data.contact_types &&
                  this.state.data.contact_types.email
                }
                readOnly
              />
              <Form.Checkbox
                label="Phone"
                name="phone"
                checked={
                  this.state.data.contact_types &&
                  this.state.data.contact_types.phone
                }
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      contact_types: {
                        ...this.state.data.contact_types,
                        phone: !this.state.data.contact_types.phone,
                      },
                    },
                  });
                }}
              />
              <Form.Checkbox
                label="Post"
                name="post"
                checked={
                  this.state.data.contact_types &&
                  this.state.data.contact_types.post
                }
                onClick={() => {
                  this.setState({
                    data: {
                      ...this.state.data,
                      contact_types: {
                        ...this.state.data.contact_types,
                        post: !this.state.data.contact_types.post,
                      },
                    },
                  });
                }}
              />
            </Form.Group> */}
            <Divider />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() =>
                  this.props.history.push(
                    `/company/${this.props.match.params.id}`
                  )
                }
              >
                <Icon name="arrow left" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                type="submit"
                color="orange"
                onClick={this.handleSubmit}
              >
                <Icon name="pencil" />
                Update
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment>
    );
  }
}
