import React from "react";

import {
  Table,
  Message,
  Button,
  Icon,
  Popup,
  Segment,
  Label,
} from "semantic-ui-react";
import Moment from "react-moment";

export default class EmployeeLogList extends React.PureComponent {
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        console.log("🚀 ~ EmployeeLogList ~ returnthis.props.data.map ~ item:", item)
        return (
          <Table.Row key={item._id}>
            <Table.Cell width={3}>
              <Moment format={"DD/MM/YYYY HH:mm"}>{item.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell width={3}>{item.loggedBy}</Table.Cell>

            <Table.Cell width={10}>{item.message} {item._sembleID &&
              <Label color={"teal"}>
                in Semble 
              </Label>}</Table.Cell>
            <Table.Cell>
              <Button color="orange" icon onClick={() => this.props.edit(item)}>
                <Icon name="pencil" />
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add Note
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Logged By</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <Popup
                position="top center"
                trigger={
                  <Button positive icon onClick={this.props.add}>
                    <Icon name="plus" />
                  </Button>
                }
                content={"Add Log"}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
    );
  }
}
