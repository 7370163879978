import React, { Component } from "react";
import { Table, Label, Message, Segment, Button, Icon, Loader } from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import commonFunctions from "../../../../commonFunctions";
import api from "../../../../actions/api";
import { Authentication } from "../../../../lib/sso";

export default class CompanyList extends Component {
  state = {
    admin_style_data: {},
    loading: false,
    sortedColumn: null,
    sortDirection: null,
  };

  componentDidMount() {
    this.setState({ loading: true });
    api.company.myCompany().then((res) => {
      this.setState({ admin_style_data: res.admin_style_data }, () => {
        this.setState({ loading: false });
      });
    });
  }

  getStyle(style, hover, exclude = []) {
    let result = commonFunctions.getStyle(this.state.admin_style_data, style, hover, exclude);
    if (this.state.hovered == hover) {
      return result.finalHover;
    } else {
      return result.finalStyle;
    }
  }

  handleSort = (clickedColumn) => () => {
    const { sortedColumn, sortDirection } = this.state;
    const { data } = this.props;

    if (sortedColumn !== clickedColumn) {
      this.setState({
        sortedColumn: clickedColumn,
        sortDirection: "ascending",
        data: _.sortBy(data, [clickedColumn]),
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      sortDirection: sortDirection === "ascending" ? "descending" : "ascending",
    });
  };

  renderBody() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((data) => {
        return (
          <Table.Row
            className={data.archived ? "warning" : ""}
            key={data._id}
            onClick={() => {
              this.props.action(data._id);
            }}
          >
            <Table.Cell>
              PN{data.ref} {data.archived ? "[Archived]" : ""}
            </Table.Cell>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.company_info}</Table.Cell>
            <Table.Cell>{data.telephone_number}</Table.Cell>
            <Table.Cell>
              {data.company_address && data.company_address.posttown ? data.company_address.posttown : "N/A"}
            </Table.Cell>
            <Table.Cell>
              {data.sendSemble && <Label color={"teal"}>Semble</Label>}
              {data.AssignedBenefits.map((item) => {
                let past = "grey";
                if (moment(item.renewal_date).isBefore(moment())) {
                  past = "red";
                }
                return (
                  <Label color={past} key={item._id}>
                    {item.benefit.title}
                  </Label>
                );
              })}
            </Table.Cell>
            {/* Only show if admin */}
            {Authentication.can("navigator_admin.permission") && (
              <Table.Cell>
                <Button
                  icon
                  color={data.archived ? "positive" : "negative"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.onToggleArchive(data._id, data.archived).then(() => {
                      // Call back or trigger any necessary updates in PaginateWrapper
                      this.props.refreshData();
                    });
                  }}
                >
                  {data.archived ? <Icon name="folder open" /> : <Icon name="archive" />}
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        );
      });
    }
  }

  render() {
    const { loading, sortedColumn, sortDirection } = this.state;
    const { data } = this.props;

    if (loading) {
      return (
        <Loader active indeterminate size="large">
          <h2>Please wait...</h2>
        </Loader>
      );
    }

    if (!data || data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button icon positive size="tiny" onClick={() => this.props.add()}>
                <Icon name="plus" /> Add New Staff Member
              </Button>
            )}
          </Segment>
        </Message>
      );
    }

    return (
      <Table sortable stackable selectable celled style={this.getStyle("companies_table", `companies_table`)}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorted={sortedColumn === "ref" ? sortDirection : null} onClick={this.handleSort("ref")}>
              Reference
            </Table.HeaderCell>
            <Table.HeaderCell sorted={sortedColumn === "name" ? sortDirection : null} onClick={this.handleSort("name")}>
              Company Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === "company_info" ? sortDirection : null}
              onClick={this.handleSort("company_info")}
            >
              Company Info
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === "telephone_number" ? sortDirection : null}
              onClick={this.handleSort("telephone_number")}
            >
              Mobile Number
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === "company_address.posttown" ? sortDirection : null}
              onClick={this.handleSort("company_address.posttown")}
            >
              Postal Town
            </Table.HeaderCell>
            <Table.HeaderCell>Benefits</Table.HeaderCell>
            {/* Admin only column to quickly archive a case */}
            {Authentication.can("navigator_admin.permission") && <Table.HeaderCell>[ADMIN] Archive</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderBody()}</Table.Body>
      </Table>
    );
  }
}
