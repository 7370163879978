import React, { Component } from "react";
import { Form, Segment, Header, Modal, Button } from "semantic-ui-react";
import { FaDownload, FaUpload } from "react-icons/fa";

class sembleUserConflict extends Component {
  state = {
    data: {
      open: false,
      pn: {},
      semble: [],
      search: "",
    },
  };

  componentDidMount() {

    if (this.props.data) {
      this.setState({
        data: { ...this.state.data, ...this.props.data },
      });
    }

  }

  handleClose = () => this.setState({ data: {
    ...this.state.data,
    open: false
  }});

  render() {
    const { data } = this.state;
    console.log("🚀 ~ sembleUserConflict ~ render ~ data:", data)
  
    return (
      <Modal open={data.open} onClose={this.handleClose} size="large">
      <Header icon="warning" content="Conflict Warning!" />
      <Modal.Content>
        <Header>User with details are already in Semble, please choose an action!</Header>
        <Form>
          <Form.Group widths="equal">
            {data.pn &&
            <Segment>
              <Header as="h4">Patient Navigator</Header>
              <Header as="h2">#{data.pn.ref}</Header>
              <p>Title: {data.pn.title}</p>
              <p>First Name: {data.pn.firstName}</p>
              <p>Last Name: {data.pn.lastName}</p>
              <p>Date Of Birth: {data.pn.dob}</p>
              <p>Gender: {data.pn.meta && data.pn.meta.gender}</p>
              <p>Telephone: {data.pn.work_number}</p>
              <p>Email Address: {data.pn.email}</p>
              <p>Address: {data.pn.address && data.pn.address.address}</p>
            </Segment>}
            {
              data.semble.map(d => {
                return (
                <Segment>
                  <Header as="h4">Semble</Header>
                  <Header as="h2">{d.fullName}</Header>
                  <p>Title: {d.title}</p>
                  <p>First Name: {d.firstName}</p>
                  <p>Last Name: {d.lastName}</p>
                  <p>Date Of Birth: {d.dob}</p>
                  <p>Gender: {d.gender}</p>
                  {d.phones.map(p => {
                    return <p>Telephone [{p.phoneType}]: {p.phoneNumber}</p>
                  })}
                  <p>Email Address: {d.email}</p>
                  <p>Address: {d.address.address}, {d.address.city}, {d.address.postcode}</p>
                  {d.numbers.map(p => {
                    return <p>Semble Number [{p.name}]: {p.value}</p>
                  })}
                  {d.labels.map(p => {
                    return <p>Label: {p.text}</p>
                  })}
                  <Form.Button
                    primary
                    onClick={() => this.handleSubmit({type: "update", semble: d.id})}
                  >
                    <FaUpload /> Update to Semble
                  </Form.Button>
                  <br/>
                  <Form.Button
                    primary
                    onClick={() => this.handleSubmit({type: "pull", semble: d.id, data: d})}
                  >
                    <FaDownload /> Pull from Semble
                  </Form.Button>
                </Segment>);
              })
            }
            
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={this.handleClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
    );
  }

  handleSubmit = (handle) => {
      this.props.submit(handle);
  };

}

export default sembleUserConflict;
