import React, { Component } from "react";
import { Input, Grid } from "semantic-ui-react";
import ResultsSelect from "./results";

export default class PostCoder extends Component {
  state = {
    input: "NR14 7PZ",
    data: {
      query: "NR14 7PZ",
    },
    address: {},
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.address !== prevProps.address) {
      this.setState({
        address: this.props.address,
      });
    }
  }

  onChange = (e) =>
    this.setState({
      input: e.target.value,
    });

  handleSelect = (event) => {
    if (this.props.addressSelectedCallback != null && event !== undefined) {
      this.setState({
        address: event,
      });
      this.props.addressSelectedCallback(event);
    }

    if (this.props.addressSelectedCallback != null && event === undefined) {
      const address = {
        summaryline: "",
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
        latitude: 0,
        longitude: 0,
        organisation: "",
      };
      this.setState({ address: address });
      this.props.addressSelectedCallback(address);
    }
  };

  search = (e) => {
    let { apiKey, identifier, country } = this.props;

    country === "" ? (country = "UK") : (country = this.props.country); // defaultProps only works if the prop is absent, not set to an empty string

    this.setState({ loading: true });

    fetch(
      `https://ws.postcoder.com/pcw/${apiKey}/address/${country}/${encodeURIComponent(
        this.state.input
      )}?format=json&lines=2&identifier=${identifier}&addTags=latitude,longitude&exclude=organisation`
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          this.setState({ data: {...data, country}, loading: false });
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        }
      );
  };

  render() {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={this.state.data.length > 0 ? "8" : "16"}>
            <Input
              name="name"
              autoComplete="off"
              value={this.state.input}
              onChange={this.onChange}
              icon={{
                name: "search",
                circular: true,
                link: true,
                style: { background: "lightgrey" },
                onClick: this.search,
              }}
              placeholder="Search Via Postcode..."
            />
          </Grid.Column>
          <Grid.Column width="8">
            {this.state.data.length > 0 && (
              <ResultsSelect
                data={this.state.data}
                addressSelectedCallback={this.handleSelect}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        {this.state.address.addressline1 && this.props.showAddress !== false && (
          <Grid.Row>
            <Grid.Column className="addressBlock" width={8}>
              <label>Address 1</label>
              <p>{this.state.address.addressline1}</p>
              <label>Address 2</label>
              <p>{this.state.address.addressline2}</p>
              <label>Street</label>
              <p>{this.state.address.street}</p>
            </Grid.Column>
            <Grid.Column className="addressBlock" width={8}>
              <label>Town</label>
              <p>{this.state.address.posttown}</p>
              <label>County</label>
              <p>{this.state.address.county}</p>
              <label>Postcode</label>
              <p>{this.state.address.postcode}</p>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}
