import React, { Component } from "react";
import { Table, Message, Segment, Button, Icon, Label } from "semantic-ui-react";
import Moment from "react-moment";

class referralNotes extends Component {
  renderBody() {
    if (this.props.data && this.props.data.length) {
      console.log(this.props);
      return this.props.data.map((c) => {
        return (
          <Table.Row
            key={c._id}
            onClick={() => this.props.action(c._id)}
          >
            <Table.Cell collapsing>{c.refNum}</Table.Cell>
            <Table.Cell collapsing>{c.logType}</Table.Cell>
            <Table.Cell collapsing>
              <Moment format={"DD/MM/YYYY HH:mm"}>{c.createdAt}</Moment>
            </Table.Cell>
            <Table.Cell>
              {c.message.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
              {c._sembleID &&
              <Label color={"teal"}>
                in Semble 
              </Label>}
            </Table.Cell>
            <Table.Cell collapsing>
              {c.logDue ? (
                <Moment format={"DD/MM/YYYY HH:mm"}>{c.logDue}</Moment>
              ) : (
                "--"
              )}{" "}
            </Table.Cell>
            <Table.Cell collapsing>{c.loggedBy}</Table.Cell>
            <Table.Cell collapsing><Label icon="file" content={c.files ? c.files.length : 0}/></Table.Cell>
            <Table.Cell collapsing textAlign="center">
              {c.visible ? (
                <Icon name="check" color="green" />
              ) : (
                <Icon name="close" color="red" />
              )}
            </Table.Cell>
            <Table.Cell collapsing textAlign="center">
              {c.myActivities ? (
                <Icon name="check" color="green" />
              ) : (
                <Icon name="close" color="red" />
              )}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {
    if (!this.props.data || this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Staff Member
              </Button>
            )}
          </Segment>
        </Message>
      );
    }

    return (
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ref</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Due Date</Table.HeaderCell>
            <Table.HeaderCell>Logged By</Table.HeaderCell>
            <Table.HeaderCell>Documents</Table.HeaderCell>
            <Table.HeaderCell>Visible</Table.HeaderCell>
            <Table.HeaderCell>Activity</Table.HeaderCell>
            {/* <Table.HeaderCell width={1} textAlign="right">
              <Button fluid icon positive size="tiny" onClick={this.props.add}>
                <Icon name="plus" />
              </Button>
            </Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderBody()}</Table.Body>
      </Table>
    );
  }
}

export default referralNotes;
