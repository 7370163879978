import React, { Component } from "react";
import { Segment, Header, Loader } from "semantic-ui-react";
import PaginateWrapper from "../common/PaginateWrapper";
import CompanyList from "../common/lists/companyList";
import api from "../../../actions/api";
import commonFunctions from "../../../commonFunctions";

class companyListDash extends Component {
  state = {
    admin_style_data: {},
    loading: false,
    companies: [],
  };

  componentDidMount() {
    this.setState({ loading: true });
    api.company.myCompany().then((res) => {
      this.setState({ admin_style_data: res.admin_style_data }, () => {
        this.setState({ loading: false });
      });
    });
  }

  getStyle(style, hover, exclude = []) {
    let result = commonFunctions.getStyle(this.state.admin_style_data, style, hover, exclude);

    if (this.state.hovered == hover) {
      return result.finalHover;
    } else {
      return result.finalStyle;
    }
  }

  getCompanies = (pageNum, pageSize, search, filters) => {
    return api.company.getAllPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  _adminToggleCompanyArchivedStatus = async (refId, isArchived) => {
    await api.company.editCompany({ archived: !isArchived }, refId);
    this.refreshData(); // Ensure to refresh or re-trigger data fetching.
  };

  refreshData = () => {
    // Option to trigger data fetching here or carry out re-render logic
    this.setState((prevState) => ({ refreshKey: !prevState.refreshKey }));
  };

  render() {
    if (this.state.loading) {
      return (
        <Loader active indeterminate size="large">
          <h2>Please wait...</h2>
        </Loader>
      );
    }

    return (
      <Segment basic className="no-pad">
        <Header
          as={"h1"}
          textAlign="center"
          className=" pageTitle"
          style={this.getStyle("companies_header", `companies_header`)}
        >
          Company List
        </Header>
        <Segment className="border">
          <PaginateWrapper
            key={this.state.refreshKey}
            dataQuery={this.getCompanies}
            search={true}
            archived_toggle={{ title: "Archived", field: "show_archived" }}
            active={"Archived"}
            render={(items) => (
              <CompanyList
                style={this.state.admin_style_data}
                data={items}
                onToggleArchive={this._adminToggleCompanyArchivedStatus}
                refreshData={this.refreshData}
                action={(id) => this.props.history.push(`/company/${id}`)}
              />
            )}
          />
        </Segment>
      </Segment>
    );
  }
}

export default companyListDash;
