import React, { Component } from "react";
import api from "../../../actions/api";
import {
  Segment,
  Header,
  Menu,
  Grid,
  Statistic,
  Modal,
  Icon,
  Tab,
  Button,
  Loader,
  Message,
} from "semantic-ui-react";
import moment from "moment";
import ReferralList from "../../_pn/common/lists/referralList";
import AssessmentList from "../../_pn/common/lists/assessmentList";
import { Link } from "react-router-dom";
import AddReferralForm from "./forms/addReferralForm";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import BMI from "./healthData/bmi";
import BP from "./healthData/bp";
import EmployeeLogList from "../common/lists/employeeLogList";
import NoteForm from "./forms/noteForm";
import { AuthContext, Authentication, Can } from "../../../lib/sso";
import SembleUserConflict from "../common/forms/sembleUserConfilict";

class Show extends Component {
  state = {
    activeItem: "assessments",
    user: {
      company: {},
      Dash: {},
    },
    note: {},
    bmi: [],
    bp: [],
    referral_list: [],
    referrals: [],
    addReferral: false,
    logLoading: false,
    toggleLogForm: false,
    refreshRef: false,
    startMasqueradeSession: false,
    openSembleConflict: false,
    semble: [],
    loading: true,
  };

  refList = React.createRef();

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([
      api.employee.getEmployee(this.props.match.params.id),
      api.employee.healthData.getWeight(this.props.match.params.id),
      api.employee.healthData.getBP(this.props.match.params.id),
    ])
      .then((res) => {
        this.setState({
          user: res[0],
          bmi: res[1],
          bp: res[2],
        });
      })
      .finally(() => { 
        this.setState({loading: false});
      })
      .catch((e) => {
        console.log(e);
      });
  };
  getReferrals = (pageNum, pageSize, search, filters) => {
    return api.referral.getAllEmployeeReferralPaged(
      this.props.match.params.id,
      {
        pageNum,
        pageSize,
        search,
        filters,
      }
    );
  };
  getAssessments = (pageNum, pageSize, search, filters) => {
    return api.assessment.getAllEmployeeAssessmentsPaged(
      this.props.match.params.id,
      {
        pageNum,
        pageSize,
        search,
        filters,
      }
    );
  };
  getLogs = (pageNum, pageSize, search, filters) => {
    return api.employee.getLogsPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  // getCovidData = (pageNum, pageSize, search, filters) => {};
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
  gotoReferral = (id) => {
    this.props.history.push(`/referral/${id}`);
  };
  addWeightData = (formData) => {
    let data = formData;
    data.date = new Date().toISOString();
    return api.employee.healthData
      .addWeight(this.props.match.params.id, data)
      .then(() => {
        this.getData();
      });
  };
  addBPData = (formData) => {
    let data = formData;
    data.date = new Date().toISOString();
    return api.employee.healthData
      .addBP(this.props.match.params.id, data)
      .then(() => {
        this.getData();
      });
  };

  renderAssessments() {
    if (this.state.refreshRef === false) {
      return (
        <Segment className="no-pad">
          <PaginateWrapper
            dataQuery={this.getAssessments}
            render={(items) => (
              <AssessmentList
                allowAdd
                add={() => {
                  console.log(this.state.user);
                  api.assessment
                    .addAssessment(this.state.user._id)
                    .then((res) => {
                      if (res.success) {
                        this.props.history.push(`/assessment/${res.id}`);
                      }
                    });
                }}
                data={items}
                action={(data) => {
                  if (data) {
                    this.props.history.push(`/assessment/${data}`);
                  }
                }}
                showCM={true}
              />
            )}
          />
        </Segment>
      );
    } else {
      return (
        <Segment basic>
          <Loader active />
        </Segment>
      );
    }
  }

  renderReferrals() {
    if (this.state.refreshRef === false) {
      return (
        <Segment className="no-pad">
          <PaginateWrapper
            dataQuery={this.getReferrals}
            render={(items) => (
              <ReferralList
                data={items}
                action={this.gotoReferral}
                add={() => this.setState({ addReferral: true })}
                allowAdd={
                  Authentication.can("navigator_admin.permission") === true ||
                  Authentication.can("organisation_case_manager.permission") ===
                    true
                    ? true
                    : false
                }
              />
            )}
          />
        </Segment>
      );
    } else {
      return (
        <Segment basic>
          <Loader active />
        </Segment>
      );
    }
  }
  renderDetails() {
    return (
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>Stuff</Header>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header>GP Details</Header>
                <Button icon>
                  <Icon name="plus" />
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
  renderBenefits() {
    return <Segment></Segment>;
  }
  renderMenu() {
    const { activeItem } = this.state;

    return (
      <Menu className="subMenu" stackable>
        <Menu.Item
          name="assessments"
          active={activeItem === "assessments"}
          onClick={this.handleMenuClick}
        >
          Health Journeys
        </Menu.Item>
        <Menu.Item
          name="referrals"
          active={activeItem === "referrals"}
          onClick={this.handleMenuClick}
        >
          Referrals
        </Menu.Item>
        <Menu.Item
          name="notes"
          active={activeItem === "notes"}
          onClick={this.handleMenuClick}
        >
          Notes
        </Menu.Item>
        <Menu.Item
          name="details"
          active={activeItem === "details"}
          onClick={this.handleMenuClick}
        >
          Details
        </Menu.Item>
        <Menu.Item
          name="analytics"
          active={activeItem === "analytics"}
          onClick={this.handleMenuClick}
        >
          Health Data
        </Menu.Item>
        {/* <Menu.Item name="benefits" active={activeItem === "benefits"} onClick={this.handleMenuClick}>Benefits</Menu.Item> */}
        {/* <Menu.Item name="covid" active={activeItem === "covid"} onClick={this.handleMenuClick}>Covid Checker</Menu.Item> */}

        <Menu.Menu position="right">
          <Can has={"navigator_admin.permission"}>
            <Menu.Item
              as={Link}
              to={`/reset_password/${this.props.match.params.id}`}
            >
              <Icon name="help" />
              Reset Password
            </Menu.Item>
            <Menu.Item
              onClick={() => this.setState({ startMasqueradeSession: true })}
            >
              <Icon name="user secret" />
              Masquerade User
            </Menu.Item>
          </Can>
          <Menu.Item
            as={Link}
            to={`/employee/${this.props.match.params.id}/edit`}
          >
            <Icon name="pencil" />
            Edit Employee
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  createNewEmp(data){
    api.company.addEmployeeToSemble(this.state.user.company._id,data).then(res => {

    })
  }

  renderHeader() {
    const { user } = this.state;

    return (
      <Segment basic className="no-marg no-pad" loading={this.state.loading}>
        <Grid className={"no-marg "}>
          <Grid.Row className="no-pad" stretched>
            <Grid.Column computer={8} mobile={16} className=" compDetails">
              <Grid celled className="detailsGrid">
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Date Of Birth:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {moment(user.dob).format("DD-MM-YYYY")}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Phone:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.work_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Work Email:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.email}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Mobile:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.mobile_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Home Phone:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.telephone_number}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Home Email:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.home_email}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Address:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {user.address && user.address.address}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} className=" compDetails">
              <Grid celled className="detailsGrid">
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Company:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {user.company && (
                        <Link to={`/company/${user.company._id}`}>
                          {user.company.name}
                        </Link>
                      )}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Working Status:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.work_status}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Position:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>{user.position}</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Primary Benefit:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {user.primary_benefit &&
                        user.primary_benefit.benefit &&
                        user.primary_benefit.benefit.title}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                {((user.company && user.company.sendSemble) || user.semble_id) &&
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as={"h4"}>Semble ID:</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Header as={"h5"}>
                      {user.semble_id ? user.semble_id : "N/A "}
                      {!user.semble_id && 
                      <Button
                      disabled
                      content="Send to Semble"
                      onClick={() => {
                        this.setState({loading: false});
                        api.employee.searchSemble({search: `${user.firstName} ${user.lastName}`, refined: user, company: user.company._id})
                          .then((res) => {
                  
                            if(res.success){
                              if(res.refined.length > 0){

                                this.setState({
                                  semble: res.refined,
                                  openSembleConflict: true,
                                })
                
                              }else{
                                this.createNewEmp({patient: user._id});
                              }
                            }
                            
                          })
                          .finally(() => {
                            this.setState({ loading: false });
                          })
                          .catch((e) => {
                            console.log(e);
                        });
                      }}
                      />
                      }
                    </Header>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Segment basic>
                      <Statistic.Group widths={2}>
                        <Statistic>
                          <Statistic.Value>
                            {user.Dash.activeRef}
                          </Statistic.Value>
                          <Statistic.Label>Active Referrals</Statistic.Label>
                        </Statistic>
                        <Statistic>
                          <Statistic.Value>{user.Dash.compRef}</Statistic.Value>
                          <Statistic.Label>Closed Referrals</Statistic.Label>
                        </Statistic>
                      </Statistic.Group>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderMenu()}
      </Segment>
    );
  }
  renderAddReferral() {
    const closeReferralModal = () => {
      this.setState({ addReferral: false });
    };

    const addReferral = (formData) => {
      let data = formData;
      this.setState({ refreshRef: true });
      data.company = this.state.user.company._id;
      api.employee
        .addReferal(this.state.user._id, data)
        .then(() => {
          closeReferralModal();
          this.getData();
          this.setState({ refreshRef: false });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ refreshRef: false });
        });
    };

    return (
      <Modal
        open={this.state.addReferral}
        onClose={closeReferralModal}
        centered={false}
      >
        <Header icon="medkit" content="Add Referral" />
        <Modal.Content>
          <AddReferralForm submit={addReferral} cancel={closeReferralModal} />
        </Modal.Content>
      </Modal>
    );
  }
  renderNoteForm() {
    const closeLoglModal = () => {
      this.setState({ toggleLogForm: false });
    };

    const addNote = (data) => {
      this.setState({ logLoading: true });
      api.employee.addLog(this.props.match.params.id, data).then(() => {
        this.setState({ logLoading: false, toggleLogForm: false });
      });
    };

    const editNote = (data) => {
      this.setState({ logLoading: true });
      api.employee
        .editLog(this.props.match.params.id, this.state.note._id, data)
        .then(() => {
          this.setState({ logLoading: false, toggleLogForm: false });
        });
    };

    const editCheck = Object.keys(this.state.note);

    return (
      <Modal
        open={this.state.toggleLogForm}
        onClose={closeLoglModal}
        centered={false}
      >
        <Header
          icon="list"
          content={editCheck.length !== 0 ? "Edit Note" : "Add Note"}
        />
        <Modal.Content>
          <NoteForm
            data={editCheck.length !== 0 ? this.state.note : undefined}
            submit={editCheck.length !== 0 ? editNote : addNote}
            close={closeLoglModal}
          ></NoteForm>
        </Modal.Content>
      </Modal>
    );
  }
  // renderCovid() {
  //   return (
  //     <PaginateWrapper
  //       // refresh={this.refreshReferrals} would be nice to get this to work
  //       dataQuery={this.getCovidData}
  //       render={(items) => (
  //         <CovidList covid={items} action={this.gotoReferral} />
  //       )}
  //     />
  //   );
  // }
  renderAnalyitics() {
    const panes = [
      // {
      //   menuItem: "Covid",
      //   render: () => (
      //     <Tab.Pane attached={false}>
      //       <Header textAlign="center" as={"h1"}>
      //         Covid Symptoms
      //       </Header>
      //       <PaginateWrapper
      //         dataQuery={this.getCovidData}
      //         render={(items) => (
      //           <CovidList covid={items} action={() => console.log("covid")} />
      //         )}
      //       />
      //     </Tab.Pane>
      //   ),
      // },
      {
        menuItem: "BMI",
        render: () => (
          <Tab.Pane attached={false}>
            <BMI
              user={this.state.user.meta}
              data={this.state.bmi}
              add={this.addWeightData}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Blood Pressure",
        render: () => (
          <Tab.Pane attached={false}>
            <BP
              user={this.state.user.meta}
              data={this.state.bp}
              add={this.addBPData}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Smoking",
        render: () => (
          <Tab.Pane attached={false}>Smoking same style as BMI</Tab.Pane>
        ),
      },
      {
        menuItem: "Drinking",
        render: () => (
          <Tab.Pane attached={false}>Drinking same style as BMI</Tab.Pane>
        ),
      },
    ];
    return (
      <Segment className="analyticsSection">
        <p className="subtle">* This data will come from employee web app</p>
        <Tab menu={{ secondary: true }} panes={panes} />
      </Segment>
    );
  }
  renderLogs() {
    if (!this.state.logLoading) {
      return (
        <PaginateWrapper
          dataQuery={this.getLogs}
          render={(items) => (
            <EmployeeLogList
              data={items}
              add={() => this.setState({ toggleLogForm: true, note: {} })}
              edit={(item) =>
                this.setState({ toggleLogForm: true, note: item })
              }
              allowAdd={true}
            />
          )}
        />
      );
    }
  }
  renderStartMasquerade() {
    return (
      <Modal
        open={this.state.startMasqueradeSession}
        onClose={() => this.setState({ startMasqueradeSession: false })}
        centered={true}
      >
        <Header
          icon="user secret"
          content={
            "Start a new masquerade session with " +
            this.state.user.firstName +
            " " +
            this.state.user.lastName
          }
        />
        <Modal.Content>
          <div style={{ padding: 5 }}>
            <Message warning>
              You are about to be logged in as{" "}
              {this.state.user.firstName + " " + this.state.user.lastName}. This
              will trigger a audit log entry and you will have full access to
              the employees account.
              <br />
              <br />
              Are you sure you want to continue?
            </Message>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => this.setState({ startMasqueradeSession: false })}
          >
            Cancel
          </Button>
          <Button
            negative
            onClick={() => {
              api.employee
                .startMasqueradeSession(this.state.user._id)
                .then((res) => {
                  if (res._redirectTo !== undefined) {
                    window.location = res._redirectTo;
                    return;
                  }
                  this.props.authentication.switchUser(res.code);
                })
                .catch((err) => {});
            }}
          >
            Start Session
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { activeItem, user, openSembleConflict, loading, semble } = this.state;
    console.log("🚀 ~ Show ~ render ~ this.state:", this.state)

    if(loading){
      return "Loading...";
    }

    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle main">
          {user.title} {user.firstName} {user.lastName} (#{user.ref})
        </Header>
        <Segment basic className="no-pad">
          {user && user.email && openSembleConflict && <SembleUserConflict data={{open: true, search: user.email, pn: user, semble}} submit={(d) => this.handleConflict(d)} />}
          {this.renderNoteForm()}
          {this.renderAddReferral()}
          {this.renderHeader()}
          <Can has={"navigator_admin.permission"}>
            {this.renderStartMasquerade()}
          </Can>
          <Segment className="no-pad">
            {activeItem === "assessments" && this.renderAssessments()}
            {activeItem === "referrals" && this.renderReferrals()}
            {activeItem === "details" && this.renderDetails()}
            {activeItem === "benefits" && this.renderBenefits()}
            {/* {activeItem === "covid" && this.renderCovid()} */}
            {activeItem === "analytics" && this.renderAnalyitics()}
            {activeItem === "notes" && this.renderLogs()}
          </Segment>
        </Segment>
      </Segment>
    );
  }
}
export default AuthContext(Show);
